<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M10 0L6 0V2L10 2V0ZM2 1L5 1V2C5 2.55228 5.44772 3 6 3L10 3C10.5523 3 11 2.55228 11 2V1L14 1C14.5523 1 15 1.44772 15 2L15 11.1716C15 11.702 14.7893 12.2107 14.4142 12.5858L12.5858 14.4142C12.2107 14.7893 11.702 15 11.1716 15L2 15C1.44771 15 1 14.5523 1 14L1 2C1 1.44772 1.44772 1 2 1Z"
    fill="currentColor"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M8 4C7.44772 4 7 4.44772 7 5V6.9998H5C4.44772 6.9998 4 7.44752 4 7.9998C4 8.55209 4.44772 8.9998 5 8.9998H7L7 11C7 11.5523 7.44772 12 8 12C8.55228 12 9 11.5523 9 11V8.9998H11C11.5523 8.9998 12 8.55209 12 7.9998C12 7.44752 11.5523 6.9998 11 6.9998L9 6.9998V5C9 4.44772 8.55228 4 8 4Z"
    fill="white"
  />
</template>
